import React, { useState } from 'react';
import '../assets/styles/ClickerGame.css'; // For any styling

const ClickerGame = () => {
    const [clickCount, setClickCount] = useState(0);

    const handleClick = () => {
        setClickCount(clickCount + 1);
    };

    return (
        <div id="game">
            <h1>Clicker Game</h1>
            <button id="clickerButton" onClick={handleClick}>Click Me!</button>
            <p>Clicks: <span id="clickCount">{clickCount}</span></p>
        </div>
    );
};

export default ClickerGame;
